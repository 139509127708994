import React, { useEffect, useRef, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import SubscribeForm from "./SubscribeForm";
import Footer from "./Footer";
import { gsap } from "gsap";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import "./web_response.css";
import "./mobile_response.css";
import Logo from "../img/logo.png";
import AboutImg from "../img/6.jpg";
import SeriesImg from "../img/stemSeries.jpeg";
import Series1 from "../img/series/1.jpg";
import Series2 from "../img/series/2.jpg";
import Series3 from "../img/series/3.jpg";
import Series4 from "../img/series/4.JPG";
import axios from "axios";

const Index = () => {
  const sectionRefs = useRef([]);
  const [currentSection, setCurrentSection] = useState(0);
  const [videos, setVideos] = useState([]);

  // Fetch videos from the backend
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/videos`
        );
        setVideos(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  const sections = useMemo(
    () => ["home", "about-us", "stem_series", "subscribe-section"],
    []
  );

  useEffect(() => {
    sectionRefs.current = sections.map(
      (_, i) => sectionRefs.current[i] ?? React.createRef()
    );
  }, [sections]);

  useEffect(() => {
    const triggerAnimation = (index) => {
      if (sectionRefs.current[index]?.current) {
        switch (index) {
          case 0:
            gsap.fromTo(
              sectionRefs.current[0].current,
              { opacity: 0, scale: 0.5, rotation: 180 },
              {
                opacity: 1,
                scale: 1,
                rotation: 0,
                duration: 2,
                ease: "elastic.out(1, 0.75)",
              }
            );
            break;
          case 1:
            gsap.fromTo(
              sectionRefs.current[1].current,
              { opacity: 0, y: 100, scale: 0.8 },
              { opacity: 1, y: 0, scale: 1, duration: 1.5, ease: "power4.out" }
            );
            break;
          case 2:
            gsap.fromTo(
              sectionRefs.current[2].current,
              { opacity: 0, y: 150, scale: 0.8, rotation: 15 },
              {
                opacity: 1,
                y: 0,
                scale: 1,
                rotation: 0,
                duration: 1.8,
                ease: "expo.out",
              }
            );
            break;
          case 3:
            gsap.fromTo(
              sectionRefs.current[3].current,
              { opacity: 0, y: 50 },
              { opacity: 1, y: 0, duration: 1.5, ease: "power4.out" }
            );
            break;
          default:
            break;
        }
      }
    };

    triggerAnimation(currentSection);
  }, [currentSection]);

  const scrollToSection = (direction) => {
    let nextSection = currentSection;
    if (direction === "down" && currentSection < sections.length - 1) {
      nextSection++;
    } else if (direction === "up" && currentSection > 0) {
      nextSection--;
    }

    setCurrentSection(nextSection);

    if (sectionRefs.current[nextSection]?.current) {
      sectionRefs.current[nextSection].current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="scroll-buttons">
        <button className="scroll-btn" onClick={() => scrollToSection("up")}>
          <FaArrowUp />
        </button>
        <button className="scroll-btn" onClick={() => scrollToSection("down")}>
          <FaArrowDown />
        </button>
      </div>

      <main className="main_container">
        <section className="home" ref={sectionRefs.current[0]}>
          <div className="Home_logo">
            <div className="logo_animated">
              <img
                src={Logo}
                alt="stem_animated_logo"
                className="stem_logo_Home"
              />
            </div>
            <h3 className="home_text">Innovation Everywhere</h3>
            <a href="#about" className="home_btn">
              LEARN MORE
            </a>
          </div>

          {/* YouTube Section */}
          <div className="youtube" id="video-container">
            {videos.length > 0 ? (
              <iframe
                id="main-link"
                width="560"
                height="315"
                src={videos[0].url} // Render the first video from the backend
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; gyroscope; web-share"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            ) : (
              <p>Loading video...</p>
            )}
          </div>
        </section>

        <section className="about-us" ref={sectionRefs.current[1]}>
          <h1 id="about" className="about-us_heading">
            About Us
          </h1>
          <div className="about_content">
            <div className="about-sub_text order1">
              <p>
                <span style={{ color: "#c5057d", opacity: 1 }}>
                  The Pioneer STEM Show{" "}
                </span>
                is a show that showcases all innovations and activities around
                Science, Technology, Engineering and Mathematics (STEM).
              </p>
            </div>
            <div className="about-us_img order2">
              <img src={AboutImg} alt="img" className="about-us_img" />
            </div>
          </div>
        </section>

        <section className="stem_series" id="stem" ref={sectionRefs.current[2]}>
          <h1 className="stem_heading">STEM Series</h1>
          <div className="stem_content">
            <div className="stem_img">
              <img src={SeriesImg} alt="img" className="stem_img" />
            </div>
          </div>
          <div className="card_container">
            <div className="card">
              <div className="imgBx">
                <Link to="/family_stem">
                  <img src={Series1} alt="" className="cards_img" />
                </Link>
              </div>
              <h1 className="card_content">
                <Link to="/family_stem" className="link">
                  Family STEM Show
                </Link>
              </h1>
            </div>

            <div className="card">
              <div className="imgBx">
                <a href="/women_stem">
                  <img src={Series2} alt="" className="cards_img" />
                </a>
              </div>
              <h1 className="card_content">
                <Link to="/women_stem" className="link">
                  Women In STEM
                </Link>
              </h1>
            </div>

            <div className="card">
              <div className="imgBx">
                <Link to="/graduates_stem">
                  <img src={Series3} alt="" className="cards_img" />
                </Link>
              </div>
              <h1 className="card_content">
                <Link to="/graduates_stem" className="link">
                  STEM Graduates
                </Link>
              </h1>
            </div>

            <div className="card">
              <div className="imgBx">
                <Link to="/buds_stem">
                  <img src={Series4} alt="" className="cards_img" />
                </Link>
              </div>
              <h1 className="card_content">
                <Link to="/buds_stem" className="link">
                  STEM Buds (School STEM Showcase)
                </Link>
              </h1>
            </div>
          </div>
        </section>

        <section className="subscribe-section" ref={sectionRefs.current[3]}>
          <SubscribeForm />
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Index;
