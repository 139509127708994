import React from "react";
import { Navigate } from "react-router-dom";

// Protected Route component that checks if the user is logged in
const ProtectedRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem("isAdminLoggedIn"); // Check local storage for login state

  if (!isLoggedIn) {
    return <Navigate to="/admin/login" />; // Redirect to login if not logged in
  }

  return children; // Render the component if logged in
};

export default ProtectedRoute;
