import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./adminLogin.css"; // Import the CSS styles for the login form

const ResetPass = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = useParams(); // Get the token from the URL
  const navigate = useNavigate();

  // Handle Password Reset
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      // Send the new password and token to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/reset-password/${token}`,
        { password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle successful password reset
      setResetMessage("Password updated successfully! Redirecting to login...");
      setTimeout(() => {
        navigate("/admin/login");
      }, 3000); // Redirect after 3 seconds
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          "Failed to reset password. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login-container">
        <div className="login-form">
          <h1>Reset Password</h1>
          <form onSubmit={handlePasswordReset}>
            {/* New Password Input */}
            <div className="input-group">
              <label>New Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your new password"
              />
            </div>

            {/* Confirm Password Input */}
            <div className="input-group">
              <label>Confirm Password:</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm your new password"
              />
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {resetMessage && <p className="reset-message">{resetMessage}</p>}

            <button type="submit" className="login-button" disabled={loading}>
              {loading ? "Resetting Password..." : "Reset Password"}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPass;
