import React, { useState, useEffect } from "react";
import axios from "axios";
import "./marketing.css"; // Import the new CSS file for marketing page styling

const AdminMarketing = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [recipients, setRecipients] = useState("");
  const [status, setStatus] = useState("");
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showContacts, setShowContacts] = useState(false);

  // Fetch all contacts on component mount
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const res = await axios.get("/api/marketing/contacts");
        setContacts(res.data);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  const handleSendEmails = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/marketing/send-email", {
        subject,
        message,
        recipients: recipients.split(","),
      });
      setStatus("Emails sent successfully");
    } catch (error) {
      setStatus("Failed to send emails");
    }
  };

  // Filter contacts based on search query
  const filteredContacts = contacts.filter(
    (contact) =>
      contact.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (contact.mobile && contact.mobile.includes(searchQuery))
  );

  // Add all contacts' emails to recipients field
  const handleAddAllEmails = () => {
    const allEmails = contacts.map((contact) => contact.email).join(",");
    setRecipients(allEmails);
  };

  return (
    <div className="marketing-container">
      <h2>Send Marketing Emails</h2>
      <form onSubmit={handleSendEmails} className="contact-form">
        <div className="input-group">
          <label>Email Subject:</label>
          <input
            type="text"
            placeholder="Email Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>

        <div className="input-group">
          <label>Email Message:</label>
          <textarea
            placeholder="Email Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>

        <div className="input-group">
          <label>Recipients (comma-separated emails):</label>
          <input
            type="text"
            placeholder="Recipients"
            value={recipients}
            onChange={(e) => setRecipients(e.target.value)}
            required
          />
        </div>

        <div className="buttons-group">
          <button type="submit" className="send-button">
            Send Emails
          </button>
          <button
            type="button"
            className="add-all-button"
            onClick={handleAddAllEmails}
          >
            Add All Emails
          </button>
        </div>
      </form>

      {status && <p className="status-message">{status}</p>}

      <br />

      {/* Show Contacts Button */}
      <button
        className="toggle-contacts-button"
        onClick={() => setShowContacts(!showContacts)}
      >
        {showContacts ? "Hide Contacts" : "Show All Contacts"}
      </button>

      {/* Searchable Contact List */}
      {showContacts && (
        <div className="contacts-container">
          <input
            type="text"
            placeholder="Search contacts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="contact-search"
          />
          <div className="contacts-list">
            {filteredContacts.length > 0 ? (
              filteredContacts.map((contact) => (
                <div key={contact._id} className="contact-item">
                  <p>
                    <strong>Name:</strong> {contact.fullName}
                  </p>
                  <p>
                    <strong>Email:</strong> {contact.email}
                  </p>
                  {contact.mobile && (
                    <p>
                      <strong>Phone:</strong> {contact.mobile}
                    </p>
                  )}
                </div>
              ))
            ) : (
              <p>No contacts found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminMarketing;
