import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./web_response.css";
import "./mobile_response.css";

const Family_stem = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch videos from the backend
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/videos`
        );
        setVideos(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div>
      <Navbar />
      <main className="main_container">
        {/* Home Section */}
        <div className="stem_section">
          <div className="stem_intro">
            <h3 className="home_text" style={{ color: "#c5057d", opacity: 1 }}>
              Family STEM Show
            </h3>
            <p>
              Representatives of our indigenous families come to learn and
              showcase what they have learnt. It's usually a fine blend of
              children and Parents and sometimes, we involve schools and their
              students.
            </p>
          </div>

          {/* Main Video */}
          <div className="stem_video">
            {loading ? (
              <p>Loading video...</p>
            ) : videos[1] && videos[1].url ? (
              <iframe
                width="560"
                height="315"
                src={videos[1].url}
                title="Family STEM Show Video"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <p>No video available</p>
            )}
          </div>
        </div>

        {/* Stem Series Section */}
        <div className="video-grid">
          {loading ? (
            <p>Loading videos...</p>
          ) : videos.length >= 5 ? (
            <>
              {/* Video 1 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[2].url}
                  title="STEM Video 1"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>

              {/* Video 2 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[3].url}
                  title="STEM Video 2"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>

              {/* Video 3 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[4].url}
                  title="STEM Video 3"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </>
          ) : (
            <p>No videos available</p>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Family_stem;
