import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { motion } from "framer-motion";

import "./web_response.css";
import "./mobile_response.css";
import "./gallery.css";

// Dynamically require all .JPG images from ../img/stembuds folder
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context("../img/stembuds", false, /\.JPG$/));

// Create an array of image data from the imported images
const imageData = images.map((src, index) => ({
  id: index + 1,
  src,
  alt: `STEM Show Image ${index + 1}`,
}));

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null); // To track selected image for slideshow
  const [currentIndex, setCurrentIndex] = useState(0); // To track current image index for slideshow
  const [visibleImages, setVisibleImages] = useState(20); // Number of visible images on the grid

  // Open the slideshow with the selected image
  const openSlideshow = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
  };

  // Close the slideshow
  const closeSlideshow = () => {
    setSelectedImage(null);
    setCurrentIndex(0);
  };

  // Set up the slideshow timer to change the image every 3 seconds
  useEffect(() => {
    if (selectedImage) {
      const timer = setInterval(() => {
        const nextIndex = (currentIndex + 1) % imageData.length;
        setSelectedImage(imageData[nextIndex]);
        setCurrentIndex(nextIndex);
      }, 3000);

      return () => clearInterval(timer); // Clear interval when closing slideshow or changing images
    }
  }, [selectedImage, currentIndex]);

  // Load more images in the gallery grid
  const showMoreImages = () => {
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 20);
  };

  return (
    <div>
      <Navbar />
      <main className="main_container">
        <h1 className="soon">Gallery</h1>

        {/* Gallery Section */}
        <div className="gallery_show">
          <div className="gallery-grid">
            {imageData.slice(0, visibleImages).map((image, index) => (
              <motion.div
                key={image.id}
                className="gallery-item"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => openSlideshow(image, index)}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="gallery-image"
                />
              </motion.div>
            ))}
          </div>
        </div>

        {/* Show More Button */}
        {visibleImages < imageData.length && (
          <button
            id="show-more-button"
            className="show-more-button"
            onClick={showMoreImages}
          >
            Show More
          </button>
        )}

        {/* Slideshow Modal */}
        {selectedImage && (
          <motion.div
            className="slideshow-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeSlideshow}
          >
            <div className="slideshow-content">
              <img
                src={selectedImage.src}
                alt={selectedImage.alt}
                className="slideshow-image"
              />
              <button className="close-slideshow" onClick={closeSlideshow}>
                &times;
              </button>
            </div>
          </motion.div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Gallery;
