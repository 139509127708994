import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./contact.css";

// Validation schema using Yup
const validationSchema = Yup.object({
  fullName: Yup.string()
    .required("Full Name is required")
    .min(2, "Name is too short"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  message: Yup.string()
    .required("Message is required")
    .min(10, "Message is too short"),
  number: Yup.string()
    .matches(/^[0-9]*$/, "Phone number must be digits only")
    .nullable(),
});

const Contact = () => {
  const [marketingOptIn, setMarketingOptIn] = useState(false); // For marketing opt-in checkbox
  const [submitSuccess, setSubmitSuccess] = useState(""); // Success message
  const [submitError, setSubmitError] = useState(""); // Error message

  const handleFormSubmission = async (values, { resetForm }) => {
    const { fullName, email, number, message } = values;

    // Payload to send to backend
    const payload = {
      fullName,
      email,
      number,
      message,
      marketingOptIn,
    };

    try {
      // Send the contact form data to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/contact/submit`,
        payload
      );

      if (response.status === 200) {
        setSubmitSuccess("Your message has been sent successfully!");
        setSubmitError("");
        resetForm(); // Reset the form after submission
      } else {
        setSubmitError("Failed to send message. Please try again.");
        setSubmitSuccess("");
      }
    } catch (error) {
      setSubmitError("An error occurred. Please try again later.");
      setSubmitSuccess("");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="contact-container">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="contact-heading"
        >
          Contact Us
        </motion.h1>

        <Formik
          initialValues={{ fullName: "", email: "", number: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmission}
        >
          {({ isSubmitting }) => (
            <Form className="contact-form">
              {/* Full Name */}
              <motion.div
                className="form-group"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <label htmlFor="fullName">Full Name</label>
                <Field
                  name="fullName"
                  type="text"
                  placeholder="Your Full Name"
                />
                <ErrorMessage
                  name="fullName"
                  component="div"
                  className="error"
                />
              </motion.div>

              {/* Email */}
              <motion.div
                className="form-group"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              >
                <label htmlFor="email">Email</label>
                <Field name="email" type="email" placeholder="Your Email" />
                <ErrorMessage name="email" component="div" className="error" />
              </motion.div>

              {/* Phone Number (Optional) */}
              <motion.div
                className="form-group"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <label htmlFor="number">Phone Number (Optional)</label>
                <Field
                  name="number"
                  type="text"
                  placeholder="Your Phone Number"
                />
                <ErrorMessage name="number" component="div" className="error" />
              </motion.div>

              {/* Message */}
              <motion.div
                className="form-group"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.5 }}
              >
                <label htmlFor="message">Message</label>
                <Field
                  as="textarea"
                  name="message"
                  placeholder="Your Message"
                  rows="5"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error"
                />
              </motion.div>

              {/* Marketing Opt-In */}
              <motion.div
                className="form-group marketing-opt-in"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.6 }}
              >
                <label>
                  <Field
                    type="checkbox"
                    name="marketingOptIn"
                    checked={marketingOptIn}
                    onChange={() => setMarketingOptIn(!marketingOptIn)}
                  />
                  Add me to the marketing list
                </label>
              </motion.div>

              {/* Submit Button */}
              <motion.div
                className="form-group"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6, delay: 0.7 }}
              >
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="submit-btn"
                >
                  {isSubmitting ? "Submitting..." : "Send Message"}
                </button>
              </motion.div>

              {/* Success/Error Messages */}
              {submitSuccess && (
                <motion.p
                  className="success-message"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {submitSuccess}
                </motion.p>
              )}
              {submitError && (
                <motion.p
                  className="error-message"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {submitError}
                </motion.p>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
