import React, { useState } from "react";
import { Link } from "react-router-dom"; // Importing Link from react-router-dom
import "./global_header.css"; // Including the header styles
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa"; // Importing Font Awesome icons
import Logo from "../img/logo.png";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="STEM Show Logo" />
        </Link>
      </div>

      {/* Hamburger menu icon */}
      <div className="menu-icon" onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}{" "}
        {/* Toggle between open (X) and closed (bars) */}
      </div>

      <nav className={`navbar ${menuOpen ? "active" : ""}`}>
        <ul>
          <li>
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="nav-link">
              About The Host
            </Link>
          </li>
          <li>
            <Link to="/gallery" className="nav-link">
              Gallery
            </Link>
          </li>

          {/* Dropdown for STEM Series */}
          <li className="stem_child dropdown-toggle" onClick={toggleDropdown}>
            STEM Series {dropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
            <ul className={`stem_series_child ${dropdownOpen ? "active" : ""}`}>
              <li>
                <Link to="/buds_stem" className="nav-link">
                  STEM Buds(School STEM Showcase)
                </Link>
              </li>
              <li>
                <Link to="/family_stem" className="nav-link">
                  Family STEM Show
                </Link>
              </li>
              <li>
                <Link to="/graduates_stem" className="nav-link">
                  STEM Graduates
                </Link>
              </li>
              <li>
                <Link to="/women_stem" className="nav-link">
                  Women In STEM
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/contact" className="nav-link contact_us">
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
