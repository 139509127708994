import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./adminLogin.css"; // Use the button styling from adminLogin.css
import "./admin.css"; // Separate CSS file for admin-specific styles

const Admin = () => {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [newUrl, setNewUrl] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Fetch all videos on component mount
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/videos`
        );
        setVideos(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  // Handle updating the selected video URL
  const handleUpdateVideo = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/videos/${id}`,
        { url: newUrl }
      );
      setMessage("Video updated successfully!");
      setVideos(
        videos.map((video) => (video._id === id ? response.data : video))
      );
      setSelectedVideo(null); // Deselect the video after updating
      setNewUrl(""); // Clear the input field
    } catch (error) {
      console.error("Error updating video:", error);
      setMessage("Failed to update the video. Please try again.");
    }
  };

  // Handle log out
  const handleSignOut = () => {
    localStorage.removeItem("isAdminLoggedIn");
    navigate("/admin/login");
  };

  return (
    <div className="admin-container">
      <header className="admin-header">
        <button
          className="login-button"
          onClick={() => navigate("/admin/marketing")}
        >
          Marketing
        </button>
        <button className="login-button" onClick={() => navigate("/")}>
          Return to Site
        </button>
        <button className="login-button" onClick={handleSignOut}>
          Sign Out
        </button>
      </header>

      <main className="admin-main">
        <h1 className="admin-title">Admin Dashboard</h1>
        <p className="admin-instructions">
          Use the form below to update the YouTube links for the embedded videos
          on your site.
        </p>

        {message && <p className="message">{message}</p>}

        <div className="video-edit-section">
          {videos.length > 0 ? (
            videos.map((video) => (
              <div key={video._id} className="video-item">
                <h3>{video.title}</h3>
                <p>Current URL: {video.url}</p>
                {selectedVideo === video._id ? (
                  <div className="edit-container">
                    <input
                      type="text"
                      placeholder="Enter new YouTube URL"
                      value={newUrl}
                      onChange={(e) => setNewUrl(e.target.value)}
                      className="edit-input"
                    />
                    <div className="edit-buttons">
                      <button
                        className="login-button"
                        onClick={() => handleUpdateVideo(video._id)}
                      >
                        Update Video
                      </button>
                      <button
                        className="login-button cancel-button"
                        onClick={() => setSelectedVideo(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    className="login-button"
                    onClick={() => setSelectedVideo(video._id)}
                  >
                    Edit
                  </button>
                )}
              </div>
            ))
          ) : (
            <p>No videos available.</p>
          )}
        </div>
      </main>
    </div>
  );
};

export default Admin;
