import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./adminLogin.css"; // Import the CSS styles for the login form

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetMessage, setResetMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Admin Login Handler
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/login`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle successful login
      localStorage.setItem("isAdminLoggedIn", "true"); // Set admin login state in localStorage
      localStorage.setItem("token", response.data.token); // Store JWT token in localStorage
      navigate("/admin"); // Redirect to admin dashboard
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Login failed. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  // Password Reset Handler
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/reset-password`,
        { email: resetEmail },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setResetMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      setResetMessage(
        error.response?.data?.message || "Failed to send reset email."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login-container">
        <div className="login-form">
          {isResettingPassword ? (
            <>
              <h1>Reset Password</h1>
              <form onSubmit={handlePasswordReset}>
                <div className="input-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                  />
                </div>
                {resetMessage && (
                  <p className="reset-message">{resetMessage}</p>
                )}
                <button
                  type="submit"
                  className="login-button"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send Reset Email"}
                </button>
                <br />
                <br />
                <br />
                <button
                  type="button"
                  className="login-button"
                  onClick={() => setIsResettingPassword(false)}
                >
                  Back to Login
                </button>
              </form>
            </>
          ) : (
            <>
              <h1>Admin Login</h1>
              <form onSubmit={handleLogin}>
                <div className="input-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                  />
                </div>
                <div className="input-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Enter your password"
                  />
                </div>
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
                <button
                  type="submit"
                  className="login-button"
                  disabled={loading}
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
              </form>
              <br />
              <p
                className="forgot-password"
                onClick={() => setIsResettingPassword(true)}
              >
                Forgot Password?
              </p>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminLogin;
