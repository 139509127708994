import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Admin from "./components/Admin";
import Buds_stem from "./components/Buds_stem";
import Contact from "./components/Contact";
import Family_stem from "./components/Family_stem";
import Graduates_stem from "./components/Graduates_stem";
import Index from "./components/Index";
import Women_stem from "./components/Women_stem";
import Gallery from "./components/Gallery";
import AdminLogin from "./components/AdminLogin";
import AdminMarketing from "./components/AdminMarketing";
import ResetPass from "./components/resetPass"; // Correct import case
import ProtectedRoute from "./components/ProtectedRoute"; // Import ProtectedRoute

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/buds_stem" element={<Buds_stem />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/family_stem" element={<Family_stem />} />
        <Route path="/graduates_stem" element={<Graduates_stem />} />
        <Route path="/women_stem" element={<Women_stem />} />

        {/* Admin Login Route */}
        <Route path="/admin/login" element={<AdminLogin />} />

        {/* Protected Admin Routes */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/marketing"
          element={
            <ProtectedRoute>
              <AdminMarketing />
            </ProtectedRoute>
          }
        />

        {/* Reset Password Route */}
        <Route path="/reset-password/:token" element={<ResetPass />} />
      </Routes>
    </Router>
  );
}

export default App;
