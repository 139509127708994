import React from "react";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa"; // Importing social media icons

const Footer = () => {
  const currentYear = new Date().getFullYear(); // No need for useState here

  return (
    <footer className="stem-show_footer">
      <h1>SocialMedia</h1>

      <ul className="social_links">
        <li>
          <a
            href="https://www.facebook.com/thepioneerstemshow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/thepioneerstemshow/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </li>
        <li>
          <a
            href="https://instagram.com/pioneerstemshow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/@thepioneerstemshow?_t=8jJJ5xeYmA9&_r=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTiktok />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@thepioneerstemshow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
        </li>
      </ul>

      <h1 className="copyright">
        © {currentYear} The Pioneer STEM Show. All rights reserved. Powered By
        <a
          href="https://www.asdesigns.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          ASDesigns
        </a>
      </h1>
    </footer>
  );
};

export default Footer;
