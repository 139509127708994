import React from "react";
import AboutImg from "../img/1.jpg"; // Import your image here
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./web_response.css";
import "./about.css";
import "./mobile_response.css";

const About = () => {
  return (
    <div>
      <Navbar />
      <main className="main_container">
        {/* About us section */}
        <section className="about-us" id="about">
          <h1 className="about-us_heading">About The Host</h1>
          <div className="about_content">
            <div className="left">
              <blockquote>
                <h4>
                  Chioma Omolaye is considered to be a multitalented Lady with
                  lots of skills in digital innovation including Machine
                  Learning/Artificial Intelligence.
                </h4>
                <h4>
                  Before she migrated to STEM, she worked with excellent MIPAN
                  members like Media Perspectives and Media Fuse Dentsu Aegis
                  network. She is a certified STEM Educator from STEM.org and
                  one of the biggest Advocates of STEM in Nigeria. She loves
                  working on STEM-based curriculum.
                </h4>
                <h4>
                  She is a member of the Electronic Media Content Owners
                  Association of Nigeria.
                </h4>
                <h4>
                  She has served in the capacity of event Host, event Judge,
                  panelist, Guest speaker.
                </h4>
                <h4>She is a devout Christian wife and mother.</h4>
                <h4>
                  She hopes that Nigerian children and youth will continue to
                  rise to contribute favorably to global innovation.
                </h4>
              </blockquote>
            </div>
            <div className="right">
              <img className="about_img" src={AboutImg} alt="Chioma Omolaye" />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default About;
