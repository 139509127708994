import React, { useState } from "react";

const SubscribeForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(""); // New optional phone number field
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true

    const contactData = { fullName: name, email, mobile: phone };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/marketing/add-contact`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactData),
        }
      );

      if (response.ok) {
        setMessage("Subscription successful!");
        setName("");
        setEmail("");
        setPhone("");
      } else {
        setMessage("Subscription failed. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false); // Reset submitting state after the request completes
    }
  };

  return (
    <section className="sub_me">
      <div className="mauticform_wrapper">
        <form onSubmit={handleSubmit} className="contact_me">
          <h3>SUBSCRIBE</h3>
          <p>
            Unlock the Wonders of STEM! Sign up for exclusive updates and
            promotions. Join our community of curious minds today!
          </p>

          <div className="mauticform-innerform">
            {/* Name Input */}
            <div className="sub_name">
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            {/* Email Input */}
            <div className="sub_email">
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* Phone Input (Optional) */}
            <div className="sub_phone">
              <input
                type="text"
                name="phone"
                value={phone}
                placeholder="Phone (optional)"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            {/* Submit Button */}
            <div className="mauticform-button-wrapper">
              <button type="submit" className="sub_btn" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Sign Me Up"}
              </button>
            </div>
          </div>

          {/* Feedback Message */}
          {message && <p>{message}</p>}
        </form>
      </div>
    </section>
  );
};

export default SubscribeForm;
